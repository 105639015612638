.section1 {
  background-image: url(./Space-bg\ 1.png);
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  /* background-position: center; */
  padding-bottom: 100px;
  /* padding: 0 50px 100px 50px; */
  position: relative;
}
.section1__body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.section1__video {
  width: 800px;
  height: auto;
}
.p1 {
  position: absolute;
  right: 0;
}
.arrow__title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow__right {
  transform: rotate(180deg);
}
.arrow__text {
  margin: 0 50px 0 50px;
}

@media screen and (max-width: 1024px) {
  .p1 {
    display: none;
  }
  .p2 {
    display: none;
  }
  .section1__video {
    width: 300px;
    height: auto;
  }
  .section2__title {
    font-size: 21px !important;
    line-height: 110%;
  }
  .arrow {
    width: 50px;
    height: auto;
  }
  .arrow__text {
    margin: 0 20px 0 20px;
    font-size: 21px;
  }
  .section1 {
    /* background-position: center; */
    padding-bottom: 20px;
  }
}
