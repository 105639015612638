.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  margin: 50px 0 200px 0;
}
.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #1e2842;
  border: 4px solid #414b63;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px 6px #01010e;
  border-radius: 30px;
}
.cryptopanties {
  font-size: 60px;
  color: white;
  line-height: 100%;
  /* or 60px */

  text-align: center;
  letter-spacing: 0.06em;
  font-family: alt;
  font-weight: 400;
}
.supporting {
  font-size: 3.5vh !important ;

  color: white;
  margin-top: 1vh;
  /* identical to box height, or 24px */
  font-family: alt;
  text-align: center;
  letter-spacing: 6%;
}
.countdown-item {
  color: #fff;
  font-weight: lighter !important;
  display: flex;
  font-family: alt !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  margin: 0;
  min-width: 10vw;
  position: relative;
}
.colon {
  font-family: Audiowide;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 108.5%;
  /* or 52px */
  bottom: 4px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #fe7a2b;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

h3 {
  font-family: Audiowide;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 108.5%;
  /* or 52px */
  margin: 20px 0 0 0 !important;
  display: flex;
  align-items: center;
  text-align: center;

  color: #fe7a2b;
}
h6 {
  font-family: Audiowide;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 108.5%;
  /* or 15px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #fe7a2b;
}
.vl {
  border-left: 1px solid rgb(255, 255, 255);
  height: 12vh;
}
.section1__disc {
  cursor: pointer;
}
.section1__disc img {
  cursor: pointer;
}
.section1__disc:hover {
  /* background: rgba(0, 0, 0, 0.2); */
  filter: brightness(55%);
}
.section1__news:hover {
  /* background: rgba(0, 0, 0, 0.2); */
  filter: brightness(85%);
}
.section1__news {
  cursor: pointer;
}
.section1__box__btns {
  margin-top: 12vh;
}
.section1__btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
/* @media screen and (height:812px) {
  .cryptopanties{
    font-size:1vh !important;

  }
  .supporting{
    font-size: 1.5vh !important;
    color: white;
  }
} */
@media screen and (max-width: 800px) {
  .countdown-wrapper {
  }
  h3 {
    position: relative;
    top: 0 !important;
    font-size: 32px !important;
  }
  .colon {
    bottom: 4px !important;
    /* font-size: 3.5vh !important; */
    font-size: 32px !important;
  }
  h6 {
    /* font-size: 1vh !important; */
    position: relative;
    top: 0px !important;
    font-size: 8px !important;
  }

  .countdown-item {
    margin: 0 !important;
    min-width: 15vw !important;
  }
  .cryptopanties {
    font-weight: normal;
    font-size: 32px !important;
    line-height: 100%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
  }
  .supporting {
    font-size: 1.5vh !important;
    color: white;
  }
  .section1__disc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__disc img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__news {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
    position: relative;
    bottom: 5vh;
  }
  .section1__news img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__socials {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__box__btns {
    text-align: center !important;
    margin-top: 10vh;
  }
  .section1__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .countdown-wrapper {
    padding: 0;
  }
  .colon {
    bottom: 0px !important;
  }
  .countdown-item {
    margin: 5px;
    min-width: 20vw !important;
  }
  .cryptopanties {
    font-size: 28px !important;
  }
}
@media screen and (width: 768px) {
  .cryptopanties {
    font-size: 60px !important;
    line-height: 100%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    color: white;
    font-weight: normal;
  }

  h3 {
    position: relative;
    /* top: 3vh !important; */
    font-size: 64px !important;
  }
  .colon {
    bottom: 11px !important;
    /* font-size: 3.5vh !important; */
    font-size: 64px !important;
  }
  h6 {
    /* font-size: 1vh !important; */
    position: relative;
    bottom: 0 !important;
    font-size: 0.85rem !important;
  }
}
@media screen and (max-width: 1024px) {
  .countdown-item {
    min-width: 14vw;
  }
  h3 {
    position: relative;
    top: 0 !important;
    font-size: 32px !important;
  }
  .colon {
    bottom: 4px !important;
    /* font-size: 3.5vh !important; */
    font-size: 32px !important;
  }
  h6 {
    /* font-size: 1vh !important; */
    position: relative;
    top: 0px !important;
    font-size: 8px !important;
  }

  .cryptopanties {
    font-weight: normal !important;
    font-size: 60px;
    line-height: 100%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    color: white;
  }
  .supporting {
    font-size: 2vh;
    color: white;
    margin-top: 1vh !important;
  }
  .section1__disc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__disc img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__news {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
    position: relative;
    bottom: 1vh;
  }
  .section1__news img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__socials {
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section1__box__btns {
    text-align: center !important;
    margin-top: 12vh;
  }
  .section1__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}

/* @media screen and (width: 912px) {
  .cryptopanties {
    font-size: 6vh !important;
  }
}
@media screen and (width: 991px) {
  .cryptopanties {
    font-size: 6vh !important;
  }
} */
